import { Footer } from '@pbl-web-archetype/shared-module';
import {
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Disclaimer } from './disclaimer-section.component';
import { useAppFooterLinks } from './use-app-footer-links.hook';

const ContactUs = () => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout
      horizontalAlignment={{
        '@initial': 'start',
        '@mobile': 'center',
        '@mobileLandscape': 'center',
      }}
      css={{
        width: '200px',
        '@mobile': { textAlign: 'center' },
        '@mobileLandscape': { textAlign: 'center' },
      }}
    >
      <HeadingText level="4" size="md" css={{ marginBottom: '$one' }}>
        {formatMessage({ id: 'STR_CONTACT_US_HEADING' })}
      </HeadingText>
      <ParagraphText size="sm">
        {formatMessage({ id: 'STR_CONTACT_US_BODY_1' })}
      </ParagraphText>
      <ParagraphText size="sm">
        {formatMessage({ id: 'STR_CONTACT_US_BODY_2' })}
      </ParagraphText>
    </StackLayout>
  );
};

export const AppFooter = () => {
  const { footerLinks, appDownloadLinks } = useAppFooterLinks();
  return (
    <Footer
      footerLinks={footerLinks}
      disclaimer={<Disclaimer />}
      footerAppDownloadAssets={appDownloadLinks}
      branding={<ContactUs />}
    />
  );
};
