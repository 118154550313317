import { RecentClaimsCardList } from '@leagueplatform/claims';
import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';

export const initMasonryRegistry = () => {
  // Registering health journey node type to Masonry WS Driver when this module is initialized
  MasonryWSDriverNodeRenderersRegistry.registerRenderer(
    'claims',
    'recent_claims_cards',
    RecentClaimsCardList,
  );
};
