import {
  LeagueConfig,
  LEAGUE_MODULE_NAMES,
  Environment,
} from '@leagueplatform/core';
import { healthCoTheme } from 'theme/theme';
import EN_LOCALES from 'locales/en.json';
import ES_LOCALES from 'locales/es.json';
import { LoadingSpinner } from 'components/loading-spinner.component';

export const routesConfig = {
  [LEAGUE_MODULE_NAMES.assessment]: '/member/health-profile/questions',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/member/health-profile',
  [LEAGUE_MODULE_NAMES.healthNeedPages]: '/health-need',
  [LEAGUE_MODULE_NAMES.onboarding]: '/onboarding',
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
};

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGEMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_APP_ENV,
} = import.meta.env;

const authConfig = {
  domain: VITE_AUTH0_DOMAIN!,
  client_id: VITE_AUTH0_CLIENT_ID!,
  redirect_uri: window.location.origin,
};

const analyticsConfig = {
  GAPropertyId: VITE_GA_PROPERTY_ID,
  segmentKey: VITE_SEGEMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL!,
  url: VITE_API_URL!,
  legacyRestApi: VITE_LEGACY_REST_API_URL!,
};

const appConfig = {
  contentUrl: VITE_CONTENT_URL!,
  appEnvironment: VITE_APP_ENV as Environment,
};

const customMethodsConfig = {
  handleLink: async (url: string) => {
    await window.open(url, '_blank', 'noreferrer');
  },
};

export const config: LeagueConfig = {
  core: {
    tenantId: 'league',
    clientId: 'archetype-web',
    auth: { clientOptions: authConfig, idleSessionLifetimeHours: 48 },
    analytics: analyticsConfig,
    api: apiConfig,
    ...appConfig,
    customMethods: customMethodsConfig,
    routing: { isUsingLeagueRouting: true },
    ui: {
      theme: healthCoTheme,
      components: {
        loading: {
          element: <LoadingSpinner />,
          text: false,
        },
        pageHeader: {
          shape: 'line',
        },
      },
    },
    i18n: {
      stringOverrides: {
        default: EN_LOCALES,
        en: EN_LOCALES,
        es: ES_LOCALES,
      },
    },
  },
  claims: {
    showClaimsSummaryBackButton: false,
    featureFlags: {
      shouldShowClaimDetails: true,
      shouldShowDownloadClaims: true,
    },
  },
  wallet: {
    showLandingPageClaimsEntry: false,
    showLandingPageHeaderBranding: true,
  },
};
