export const LanguageLinks = [
  {
    label: 'Español',
    lang: 'es',
  },
  {
    label: '中文',
    lang: 'zh',
  },
  {
    label: 'Tiếng Việt',
    lang: 'vi',
  },
  {
    label: '한국어',
    lang: 'ko',
  },
  {
    label: 'Tagalog',
    lang: 'tl',
  },
  {
    label: '日本語',
    lang: 'ja',
  },
  {
    label: 'Deutsch',
    lang: 'de',
  },
  {
    label: 'Kreyòl',
    lang: 'ht',
  },
  {
    label: 'Français',
    lang: 'fr',
  },
  {
    label: 'العربية',
    lang: 'ar',
    dir: 'rtl',
  },
  {
    label: 'Polski',
    lang: 'pl',
  },
  {
    label: 'یسراف',
    lang: 'ur',
    dir: 'rtl',
  },
  {
    label: 'Português',
    lang: 'pt',
  },
  {
    label: 'Italiano',
    lang: 'it',
  },
  {
    label: 'More...',
    lang: 'en',
  },
];
