import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  GDSParagraphTextProps,
  GDSTextActionProps,
  ParagraphText,
  StackItem,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useIsAuthenticated } from '@pbl-web-archetype/shared-module/src/hooks/use-is-authenticated.hook';
import { EXTERNAL_LINKS } from 'common/links';
import { LanguageLinks } from './language-links.config';

const DisclaimerBodyText = ({
  children,
  ...props
}: GDSParagraphTextProps<'p' | 'span'>) => (
  <ParagraphText size="xs" {...props} css={{ ...props.css }}>
    {children}
  </ParagraphText>
);

const ExternalLink = ({ css, children, ...props }: GDSTextActionProps<'a'>) => (
  <TextAction
    as="a"
    target="_blank"
    rel="noreferrer"
    css={{
      fontWeight: 'normal !important',
      fontSize: '$caption',
      fontFamily: '$caption',
      lineHeight: '$caption',
      letterSpacing: 'normal',
      textDecoration: 'underline',
      ...css,
    }}
    {...props}
  >
    {children}
  </TextAction>
);

export const Disclaimer = () => {
  const { formatMessage } = useIntl();

  const { isAuthenticated } = useIsAuthenticated();

  const handleClick = (eventDetail: string) => {
    if (isAuthenticated) {
      trackAnalyticsEvent(EVENT_NAME.RESOURCE_CLICKED, {
        detail: eventDetail,
        sub_product_area: 'footer',
      });
    }
  };

  return (
    <StackLayout spacing="$two" css={{ paddingBottom: '$three' }}>
      <StackItem>
        <TextAction
          css={{ fontWeight: '700', marginBottom: '$one' }}
          size="medium"
        >
          {formatMessage({ id: 'STR_FOOTER_LANGUAGE_ASSISTANCE_HEADING' })}
        </TextAction>
        <StackLayout
          as="ul"
          orientation="horizontal"
          css={{
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          {LanguageLinks.map((language) => (
            <StackLayout
              key={language.lang}
              as="li"
              css={{
                minWidth: '80px',
                marginRight: '$two',
                marginBottom: '$half',
                flexWrap: 'nowrap',
              }}
            >
              <ExternalLink
                href={EXTERNAL_LINKS.LANGUAGES_AND_DISCRIMINATION}
                onClick={() => handleClick(language.label)}
                lang={language.lang}
                dir={language.dir}
              >
                {language.label}
              </ExternalLink>
            </StackLayout>
          ))}
        </StackLayout>
      </StackItem>

      <DisclaimerBodyText>
        {formatMessage({ id: 'STR_FOOTER_COPYRIGHT' })}
      </DisclaimerBodyText>
    </StackLayout>
  );
};
