// Auto-generated file: Do not modify directly. Changes may be overwritten. Version: 1.1.0.

/*
 * Following tokens have been deprecated in version 1.1.0, please update your theme:
 *
 * Interactive/Focus has been split into two separate tokens. Please define 'Interactive/Focus Inner' and 'Interactive/Focus Outer' instead.
 * 'Interactive/Background Default' is deprecated. Please use 'Input/Background Default' instead.
 * 'Interactive/Background Disabled' is deprecated. Please use 'Input/Background Disabled' instead.
 * 'Interactive/Background Hovered' is deprecated. Please use 'Input/Background Hovered' instead.
 * 'Interactive/Background Pressed' is deprecated. Please use 'Input/Background Pressed' instead.
 * 'Interactive/Border Default' is deprecated. Please use 'Input/Border Default' instead.
 * 'Interactive/Border Hovered' is deprecated. Please use 'Input/Border Hovered' instead.
 * 'Interactive/Border Disabled' is deprecated. Please use 'Input/Border Disabled' instead.
 * 'Interactive/Border Critical Default' is deprecated. Please use 'Input/Border Critical' instead.
 * 'Pill' is deprecated. Please use 'Circle' instead.
 */

import type { GDSTheme } from '@leagueplatform/genesis-core';

export const healthCoTheme: GDSTheme = {
  colors: {
    surface: {
      background: {
        primary: '#FFFFFFFF',
        secondary: '#F5F5F5FF',
        tertiary: '#F1FBFFFF',
      },
      card: {
        primary: '#F5F5F5FF',
        secondary: '#FFFFFFFF',
        disabled: '#D7D7D7FF',
      },
    },
    onSurface: {
      text: {
        primary: '#333333FF',
        subdued: '#888888FF',
        reversed: '#FFFFFFFF',
        critical: '#BA1B3BFF',
        success: '#627D32FF',
        warning: '#333333FF',
      },
      border: { subdued: '#E0E3E6FF', default: '#959595FF' },
      icon: { primary: '#636363FF' },
    },
    interactive: {
      action: {
        primary: '#006594FF',
        hovered: '#208BBDFF',
        pressed: '#005075FF',
        subdued: '#66686BFF',
        disabled: '#D6D6D6FF',
      },
      icon: { default: '#FFFFFFFF', disabled: '#949494FF' },
      background: {
        default: '#FFFFFFFF',
        disabled: '#E8EAEDFF',
        hovered: '#F8F8F8FF',
        pressed: '#F0F0F0FF',
      },
      border: {
        default: '#949494FF',
        hovered: '#005696FF',
        disabled: '#D6D6D6FF',
        critical: { default: '#A12127FF' },
      },
      focus: { inner: '#11398AFF', outer: '#11398AFF' },
    },
    critical: {
      background: { subdued: '#F9E1E2FF', secondary: '#FFFFFFFF' },
      border: { default: '#A12127FF' },
      icon: '#CB2A32FF',
    },
    warning: {
      background: { subdued: '#FAE8D6FF', secondary: '#FFFFFFFF' },
      border: { default: '#DE6F12FF' },
      icon: '#DE6F12FF',
    },
    success: {
      background: { subdued: '#E3F7F0FF', secondary: '#FFFFFFFF' },
      border: { default: '#159369FF' },
      icon: '#159369FF',
    },
    highlight: {
      background: { subdued: '#E8F8FFFF', secondary: '#FFFFFFFF' },
      border: { default: '#2E5CDFFF' },
      icon: '#19A7E9FF',
    },
    decorative: {
      brand: {
        primary: {
          darkest: '#074664FF',
          dark: '#00699AFF',
          default: '#008BCCFF',
          bright: '#6BC5EFFF',
          brightest: '#BBE9FFFF',
          pastel: '#F1FBFFFF',
        },
        secondary: {
          darkest: '#074E7AFF',
          dark: '#1C7EBBFF',
          default: '#9BC8E4FF',
          bright: '#9BD9FFFF',
          brightest: '#C0E7FFFF',
          pastel: '#F1FAFFFF',
        },
        tertiary: {
          darkest: '#845A09FF',
          dark: '#C2891BFF',
          default: '#F2A818FF',
          bright: '#F5C464FF',
          brightest: '#FFE4B0FF',
          pastel: '#FFF8EAFF',
        },
      },
    },
    primary: {
      background: {
        default: '#006594FF',
        hovered: '#208BBDFF',
        pressed: '#005075FF',
        critical: {
          default: '#CB2A32FF',
          hovered: '#A51834FF',
          pressed: '#711124FF',
        },
      },
      text: { default: '#FFFFFFFF', critical: { default: '#FFFFFFFF' } },
    },
    secondary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#208BBDFF',
        pressed: '#F0F0F0FF',
      },
      text: { default: '#006594FF', hovered: '#FFFFFFFF' },
      border: { default: '#006594FF' },
    },
    tertiary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#208BBDFF',
        pressed: '#F0F0F0FF',
      },
      text: { default: '#006594FF', hovered: '#FFFFFFFF' },
      border: { default: '#707E89FF' },
    },
    input: {
      border: {
        success: '#159369FF',
        warning: '#DE6F12FF',
        default: '#949494FF',
        hovered: '#005696FF',
        disabled: '#D6D6D6FF',
        critical: '#A12127FF',
      },
      background: {
        default: '#FFFFFFFF',
        disabled: '#E8EAEDFF',
        hovered: '#F8F8F8FF',
        pressed: '#F0F0F0FF',
      },
    },
  },
  shadows: {
    card: { x: 0, y: 2, blur: 6, spread: 0, color: '#00000014' },
    dropdown: { x: 0, y: 4, blur: 8, spread: 0, color: '#0000001A' },
  },
  typography: {
    headingOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 40,
      fontSize: 32,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 32,
      fontSize: 24,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingThree: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 28,
      fontSize: 20,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingFour: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 18,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOneSecondary: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwoSecondary: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    label: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    overline: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'UPPER',
      textDecoration: 'none',
    },
    caption: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    tab: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 12,
      fontSize: 10,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
  },
  spacing: {
    none: 0,
    quarter: 4,
    half: 8,
    threeQuarters: 12,
    one: 16,
    oneAndHalf: 24,
    two: 32,
    twoAndHalf: 40,
    three: 48,
    four: 64,
    five: 80,
  },
  borderWidths: { thin: 1, thick: 2, outerFocus: 3, innerFocus: 3 },
  borderRadii: {
    none: 0,
    thin: 1,
    thick: 2,
    small: 3,
    medium: 6,
    large: 9,
    extraLarge: 12,
    huge: 20,
    circle: 99999,
    pill: 99999,
    button: 99999,
    inputField: 6,
  },
  zIndices: {
    default: 1,
    sticky: 100,
    dropdown: 4000,
    tooltip: 5000,
    modal: 8000,
    toast: 9000,
  },
  rem: {
    fontSizes: {
      headingOne: 2,
      headingTwo: 1.5,
      headingThree: 1.25,
      headingFour: 1.125,
      subtitleOne: 1,
      subtitleTwo: 0.875,
      bodyOne: 1,
      bodyOneSecondary: 1,
      bodyTwo: 0.875,
      bodyTwoSecondary: 0.875,
      buttonOne: 1,
      buttonTwo: 0.875,
      label: 0.75,
      overline: 0.75,
      caption: 0.75,
      tab: 0.625,
    },
    lineHeights: {
      headingOne: 2.5,
      headingTwo: 2,
      headingThree: 1.75,
      headingFour: 1.5,
      subtitleOne: 1.5,
      subtitleTwo: 1.25,
      bodyOne: 1.5,
      bodyOneSecondary: 1.5,
      bodyTwo: 1.25,
      bodyTwoSecondary: 1.25,
      buttonOne: 1.5,
      buttonTwo: 1.25,
      label: 1,
      overline: 1,
      caption: 1,
      tab: 0.75,
    },
    baseSize: 16,
  },
  fonts: {
    headings:
      "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    body: "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  },
  transitions: { defaultTime: '200ms' },
};
