import {
  FooterLinkProps,
  AssetLinkProps,
} from '@pbl-web-archetype/shared-module';
import GooglePlayBadge from 'assets/GooglePlayStoreLogo.svg';
import AppleStoreBadge from 'assets/AppleAppStoreLogo.svg';
import { useIntl } from '@leagueplatform/locales';
import { EXTERNAL_LINKS } from 'common/links';

// TODO: GET REAL Link Values: https://everlong.atlassian.net/browse/PREM-142
const APP_DOWNLOAD_ASSET_LINKS: AssetLinkProps[] = [
  {
    src: AppleStoreBadge,
    url: EXTERNAL_LINKS.APPLE_STORE_APP,
    alt: 'apple store app download link',
  },
  {
    src: GooglePlayBadge,
    url: EXTERNAL_LINKS.GOOGLE_PLAY_STORE_APP,
    alt: 'google play app download link',
  },
];

export const useAppFooterLinks = () => {
  const { formatMessage } = useIntl();

  const footerLinks: FooterLinkProps[] = [
    {
      text: formatMessage({ id: 'STR_TERMS_OF_SERVICE' }),
      url: EXTERNAL_LINKS.TERMS,
    },
    {
      text: formatMessage({ id: 'STR_PRIVACY_POLICY' }),
      url: EXTERNAL_LINKS.PRIVACY,
    },
  ];

  return {
    footerLinks,
    appDownloadLinks: APP_DOWNLOAD_ASSET_LINKS,
  };
};
