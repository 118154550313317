import React from 'react';
import { GDSColor, StackLayout } from '@leagueplatform/genesis-core';
import { AssetLink, AssetLinkProps } from '../asset-links/asset-link.component';
import { FooterLinkProps } from './footer-items/footer-link-items.component';
import { FooterLinksAndIconsSection } from './footer-items/footer-links-container.component';

const FooterLayoutWrapper = ({
  children,
  backgroundColor,
}: {
  children: React.ReactNode;
  backgroundColor: GDSColor;
}) => (
  <StackLayout
    as="footer"
    horizontalAlignment="center"
    css={{
      backgroundColor,
      marginTop: '$two',
    }}
  >
    <StackLayout
      spacing="$one"
      orientation={{
        '@initial': 'vertical',
        '@tablet': 'horizontal',
        '@laptop': 'horizontal',
        '@desktop': 'horizontal',
      }}
      horizontalAlignment="center"
      css={{
        maxWidth: '920px',
        padding: '$two $two',
        marginTop: '$one',
        '@laptop': {
          padding: '$one $one',
        },
        '@desktop': {
          padding: '$one $one',
        },
      }}
    >
      {children}
    </StackLayout>
  </StackLayout>
);

export const Footer = ({
  disclaimer,
  footerAppDownloadAssets,
  branding,
  footerLinks,
  secondaryFooterLinks,
  footerIcons,
  backgroundColor = '$surfaceBackgroundSecondary',
  textColor,
}: {
  disclaimer: React.ReactNode;
  footerAppDownloadAssets: AssetLinkProps[];
  branding?: React.ReactNode;
  footerLinks: FooterLinkProps[];
  secondaryFooterLinks?: FooterLinkProps[];
  footerIcons?: AssetLinkProps[];
  backgroundColor?: GDSColor;
  textColor?: GDSColor;
}) => (
  <FooterLayoutWrapper backgroundColor={backgroundColor}>
    {/* Branding + App Download Links Section */}
    <StackLayout
      orientation="vertical"
      horizontalAlignment={{
        '@initial': 'center',
        '@tablet': 'start',
        '@laptop': 'start',
        '@desktop': 'start',
      }}
      spacing="$one"
      css={{
        flexGrow: '0',
        margin: '$one',
      }}
    >
      {!!branding && branding}
      <StackLayout spacing="$half">
        {footerAppDownloadAssets.map((item) => (
          <AssetLink
            key={item.url}
            src={item.src}
            alt={item.alt}
            url={item.url}
          />
        ))}
      </StackLayout>
    </StackLayout>

    {/* External and Social Links and Disclaimer Section */}
    <StackLayout
      orientation="vertical"
      horizontalAlignment={{
        '@initial': 'center',
        '@tablet': 'stretch',
        '@laptop': 'stretch',
        '@desktop': 'stretch',
      }}
      css={{
        margin: '$one',
      }}
    >
      <FooterLinksAndIconsSection
        footerLinks={footerLinks}
        secondaryFooterLinks={secondaryFooterLinks}
        footerIcons={footerIcons}
        textColor={textColor}
      />
      {disclaimer}
    </StackLayout>
  </FooterLayoutWrapper>
);
