import * as React from 'react';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useAuth } from '@leagueplatform/auth-utils';
import { Redirect } from '@leagueplatform/routing';
import { BasePage } from 'components/BasePage';

export const AuthorizedRoutes = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isAuthenticated, error } = useAuth({
    redirectToLogInWhenSignedOut: true,
  });

  if (error) return <Redirect push to="/" />;

  if (!isAuthenticated) return <LoadingIndicator />;

  return <BasePage>{children}</BasePage>;
};
