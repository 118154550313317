export const EXTERNAL_LINKS = {
  ABOUT: 'https://google.com',
  CONTACT_US: 'https://google.com',
  FORMS: 'https://google.com',
  GET_HELP: 'https://google.com',
  TERMS: 'https://google.com',
  PRIVACY: 'https://google.com',
  MEDICAL_POLICIES: 'https://google.com',
  PARTNERS: 'https://google.com',
  TRANSPARENCY: 'https://google.com',
  FRAUD: 'https://google.com',
  CODE_OF_CONDUCT: 'https://google.com',
  DATA_COLLECTION: 'https://google.com',
  APPLE_STORE_APP: 'https://google.com',
  GOOGLE_PLAY_STORE_APP: 'https://google.com',
  LANGUAGES_AND_DISCRIMINATION: 'https://google.com',
};
