import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { GDSIconProps } from '@leagueplatform/genesis-core';
import type { LocalesKey } from 'types/types';

export const NAV_IDS: { [key: string]: LocalesKey } = {
  HOME: 'STR_HOME',
  BENEFITS: 'STR_BENEFITS',
  CLAIMS: 'STR_CLAIMS',
  CARE: 'STR_GET_CARE',
  JOURNEY: 'STR_JOURNEY',
  HEALTH_PROFILE: 'STR_HEALTH_PROFILE',
  ABOUT_ME: 'STR_ABOUT_ME',
};

type Keys = keyof typeof NAV_IDS;
type NavIds = (typeof NAV_IDS)[Keys];

export enum LinkType {
  NAV = 'navLink',
  LINK = 'link',
}

export type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
  message_id: string;
  icon?: GDSIconProps['icon'];
};

export interface NavLink extends NavItem {
  message_id: NavIds;
  type: LinkType;
}

export const useNavLinksList = () => {
  const navLinksList: NavItem[] = [
    {
      to: '/home',
      message_id: NAV_IDS.HOME,
      icon: 'illustrativeHome',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.wallet),
      message_id: NAV_IDS.BENEFITS,
      icon: 'capabilityWallet',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.claims),
      message_id: NAV_IDS.CLAIMS,
      icon: 'capabilityClaims',
    },
  ];

  return {
    navLinks: navLinksList,
  };
};
